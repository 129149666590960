document.addEventListener('DOMContentLoaded', () => {
  document.documentElement.classList.remove('_preload')

  const element = document.querySelector('.site-logo-wrapper')

  if (element) {
    const logoOffset = window.getComputedStyle(document.documentElement).getPropertyValue('--site-logo-offset')
    const logoOffsetPx = Math.floor(window.innerWidth * (logoOffset.replace('vw', '') / 100))

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.boundingClientRect.y < 0) {
            if (entry.isIntersecting) {
              element.classList.remove('is-fading-out')
              element.offsetWidth
              element.classList.add('is-fading-in')
            } else {
              element.classList.remove('is-fading-in')
              element.offsetWidth
              element.classList.add('is-fading-out')
            }
          }
        })
      },
      {
        rootMargin: `${logoOffsetPx}px 0px 0px 0px`,
        threshold: [1],
      }
    )

    observer.observe(element)
  }
})
